import { defineStore } from 'pinia'
import axios from 'axios'
import { Credentials, LoginResponse, RegisterResponse, User } from '../types'

const backendUrl = `${import.meta.env.VITE_BASE_URL}/api`

function isTokenExpired(token: string) {
  const expiry = JSON.parse(atob(token.split('.')[1])).exp
  return Math.floor(new Date().getTime() / 1000) >= expiry
}

export const useUserStore = defineStore('user', {
  state: () => ({
    token: <string | null>null,
    user: <User | null>null,
  }),
  getters: {
    isAuthenticated: (state) => {
      if (state.token && !isTokenExpired(state.token)) {
        return true
      }
      return false
    }

  },
  actions: {
    async login(credentials: Credentials) {
      const response = await axios.post<LoginResponse>(`${backendUrl}/login`, credentials)
      if (response.status === 200) {
        this.token = response.data.token
        this.user = response.data.user
      }
    },

    async register(credentials: Credentials) {
      const response = await axios.post<LoginResponse>(`${backendUrl}/register`, credentials)
      if (response.status === 200) {
        this.token = response.data.token
        this.user = response.data.user
      }
    },

    async logout() {
      this.token = null
      this.user = null
    },
  },
  persist: true,
})
