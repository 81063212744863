import { createApp } from 'vue'
import i18n from './i18n'
import { createVuestic } from 'vuestic-ui'
// import { createGtm } from '@gtm-support/vue-gtm'

import stores from './stores'
import router from './router'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import App from './App.vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

import { VueClipboard } from '@soerenmartius/vue3-clipboard'

import { createPlausible } from 'v-plausible/vue'
const app = createApp(App)

const plausibleDomain = import.meta.env.VITE_PLAUSIBLE_DOMAIN
const plausibleHost = import.meta.env.VITE_PLAUSIBLE_HOST

if (plausibleDomain) {
  const plausible = createPlausible({
    init: {
      domain: plausibleDomain,
      apiHost: plausibleHost,
      trackLocalhost: true,
    },
    settings: {
      enableAutoOutboundTracking: true,
      enableAutoPageviews: true,
    },
    partytown: false,
  })
  app.use(plausible)
}

app.use(stores)
app.use(router)
app.use(i18n)
app.use(VueClipboard)

app.use(createVuestic({ config: vuesticGlobalConfig }))
app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)

app.mount('#app')
